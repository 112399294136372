<template>
  <div class="automation-list-container">
    <b-row
      v-if="analytics"
      class="match-height"
    >
      <b-col>
        <card-statistic
          color="secondary"
          icon="HomeIcon"
          :value="analytics.total"
          title="Tất cả"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Tự động hóa</b-card-title>
        <crud-buttons
          name="Tự động hóa"
          modal="modal-automation"
          :selected-rows="selectedRows"
          :show-export="false"
          :show-import="false"
          :enable-add="$can('create', 'automation')"
          :hide-delete="!$can('delete', 'automation')"
          @on-delete="onDelete"
          @on-add="resetItem"
          @on-import-file="importData"
          @on-export="exportData"
          @download-template-file="downloadTemplateFile"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-automation-detail
                    class="font-weight-bold"
                    @click="onSelectItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>
                <span v-else-if="props.column.field === 'sendingTime'">
                  {{ parseDateToString(props.row.sendingTime, 'DD/MM/YYYY HH:mm') }}</span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Tự động hóa"
                    modal="modal-automation"
                    :hide-edit="!$can('update', 'automation')"
                    :hide-delete="!$can('delete', 'automation')"
                    @on-edit="onSelectItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <automation-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <automation-detail-modal :item-id="item ? item.id : null" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,

} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import CardStatistic from "@/views/components/CardStatistic.vue";
// eslint-disable-next-line import/no-cycle
import useAutomationGroupList from "./useAutomationList";
import AutomationModal from '../modal/AutomationModal.vue';
import AutomationDetailModal from '../detail/AutomationDetailModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    TableActions,
    CrudButtons,
    AutomationDetailModal,
    VueGoodTable,
    CardStatistic,
    AutomationModal,
  },

  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      fetchAutomationGroups,
      deleteAutomationGroups,
      onSelectItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
      parseDateToString,
      downloadTemplateFile,
    } = useAutomationGroupList();

    return {
      analytics,
      item,
      columns,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      fetchAutomationGroups,
      deleteAutomationGroups,
      onSelectItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
      parseDateToString,
      downloadTemplateFile,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(automation) {
      const deleteObjects = automation && automation.id > 0 ? [automation] : this.selectedRows;
      this.deleteAutomationGroups(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

</style>
