<template>
  <b-modal
    id="modal-automation"
    ref="refModal"
    title="Tự động hóa"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên kịch bản <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Chúc mừng sinh nhật"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="template"
              rules="required"
            >
              <select-template
                v-model="itemLocal.template"
                label="Mẫu gửi tin"
                required
                :default-value="itemLocal.template"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="zaloAccount"
              rules="required"
            >
              <select-zalo-account
                v-model="itemLocal.zaloAccount"
                required
                label="Tài khoản gửi tin"
                :default-value="itemLocal.zaloAccount"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="contactGroup"
              rules="required"
            >
              <select-contact-group
                v-model="itemLocal.contactGroup"
                label="Nhóm liên hệ"
                required
                :default-value="itemLocal.contactGroup"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sendingTime"
              rules="required"
            >
              <select-date-time
                v-model="itemLocal.sendingTime"
                required
                :default-value="itemLocal.sendingTime"
                label="Thời gian gửi"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>

        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectTemplate from '@/views/components/SelectTemplate.vue';
import SelectZaloAccount from '@/views/components/SelectZaloAccount.vue';
import SelectContactGroup from '@/views/components/SelectContactGroup.vue';
import SelectDateTime from '@/views/components/SelectDateTime.vue';
import useAutomationModal from './useAutomationModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    SelectTemplate,
    SelectZaloAccount,
    SelectContactGroup,
    SelectDateTime,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
    } = useAutomationModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
./useAutomationModal
./useAutomationModal
